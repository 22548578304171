import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
import Background from '../components/background';

interface InfinityState {
  count: number;
  isAboutOpen: boolean;
  isExperienceLaunched: boolean;
  isMuted: boolean;
  isShareOpen: boolean;
}

interface Action {
  type: string;
  payload: number;
}

const reducer = (
  state: InfinityState, action: Action,
) => {
  switch (action.type) {
    case 'LAUNCH_EXPERIENCE':
      return { ...state, isExperienceLaunched: true };
    case 'INCREMENT':
      return { ...state, count: state.count + 1 };
    case 'SET_COUNT':
      return { ...state, count: action.payload };
    case 'TOGGLE_ABOUT':
      return { ...state, isAboutOpen: !state.isAboutOpen };
    case 'TOGGLE_MUTE':
      return { ...state, isMuted: !state.isMuted };
    case 'TOGGLE_SHARE':
      return { ...state, isShareOpen: !state.isShareOpen };
    default:
      return state;
  }
};

const launchExperience = () => (
  next: (action: Action) => {},
) => (action: Action) => {
  if (action.type === 'LAUNCH_EXPERIENCE') {
    Background.setRandomColor();
    Background.startCycle();
  }
  next(action);
};

const initialState = {
  count: 0,
  isAboutOpen: false,
  isExperienceLaunched: false,
  isShareOpen: false,
  isMuted: false,
};

const createStore = () => reduxCreateStore(
  reducer, initialState, applyMiddleware(launchExperience),
);

export default createStore;
