const INTERVAL = 12000;

const COLORS = [
  'blue-light',
  'blue',
  'brown',
  'green',
  'grey',
  'pink-bright',
  'pink',
  'purple',
  'red',
  'tangerine',
  'turchese',
  'white',
  'yellow',
];

let currentColor = '';

const getRandomColorClass = () => `--${COLORS[
  Math.floor(Math.random() * COLORS.length)]}`;

const setRandomColor = () => {
  const aboutSection = document.querySelector('.about-section');
  if (!aboutSection || !aboutSection.classList.contains('--visible')) {
    if (currentColor) {
      document.body.classList.remove(currentColor);
    }
    currentColor = getRandomColorClass();
    document.body.classList.add(currentColor);
  }
};

const setTransitionable = () => new Promise((resolve) => {
  setTimeout(() => {
    document.body.classList.add('--transitionable');
    resolve();
  }, 100);
});

async function startCycle() {
  await setTransitionable();
  setInterval(setRandomColor, INTERVAL);
}

export default { setRandomColor, startCycle, getRandomColorClass };
